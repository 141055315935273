/* tslint:disable */
/* eslint-disable */
/**
 * wiegaaterkoffiehalen OpenAPI
 * wiegaaterkoffiehalen OpenAPI
 *
 * The version of the OpenAPI document: 1.1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MyInvite,
} from '../models/index';
import {
    MyInviteFromJSON,
    MyInviteToJSON,
} from '../models/index';

export interface InviteInviteIdGetRequest {
    inviteId: string;
}

/**
 * 
 */
export class InviteApi extends runtime.BaseAPI {

    /**
     * Get invite
     */
    async inviteInviteIdGetRaw(requestParameters: InviteInviteIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MyInvite>> {
        if (requestParameters['inviteId'] == null) {
            throw new runtime.RequiredError(
                'inviteId',
                'Required parameter "inviteId" was null or undefined when calling inviteInviteIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invite/{inviteId}`.replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters['inviteId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MyInviteFromJSON(jsonValue));
    }

    /**
     * Get invite
     */
    async inviteInviteIdGet(requestParameters: InviteInviteIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MyInvite> {
        const response = await this.inviteInviteIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
