import * as Sentry from "@sentry/react";

if (import.meta.env.VITE_SENTRY == "enable") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    release: import.meta.env.PACKAGE_VERSION,
    ignoreErrors: ["Response returned an error code", "process is not defined"],
    beforeSend(event) {
      console.log("Sentry before send:", event);
      return event;
    },
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      }),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [[400, 599]]
      }),
      Sentry.feedbackIntegration({
        showBranding: false,
        colorScheme: "dark",
        themeDark: {
          background: "#1e2333",
          accentBackground: "#e07a5f",
          outline: "0px"
        },
        triggerLabel: "Feedback",
        formTitle: "Feedback of bug doorgeven",
        submitButtonLabel: "Verstuur",
        cancelButtonLabel: "Annuleren",
        confirmButtonLabel: "Verstuur",
        addScreenshotButtonLabel: "Voeg screenshot toe",
        removeScreenshotButtonLabel: "Verwijder screenshot",
        nameLabel: "Naam",
        namePlaceholder: "Vul hier je naam in",
        emailLabel: "E-mail",
        emailPlaceholder: "Vul hier je e-mail in",
        isRequiredLabel: "(verplicht)",
        messageLabel: "Bericht",
        messagePlaceholder: "Beschrijf hier je feedback of bug",
        successMessageText: "Bedankt voor je feedback!"
      })
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  });
}
